<template>
  <component :is="tag" v-if="!isHtmlContent" :class="contentContainerClass" :style="customStyle">
    {{ text }}
  </component>
  <div v-else-if="isHtmlContent" :class="contentContainerClass" v-html="html" :style="customStyle" />
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { PropType } from 'vue';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'LibraryTitle',

  props: {
    tag: {
      description: 'if html is provided, tag cannot be changed as it uses div',
      required: false,
      type: String as PropType<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>,
      default: 'h1',
    },
    text: {
      required: false,
      type: String,
    },

    html: {
      required: false,
      type: String,
    },

    customStyle: {
      required: false,
      type: String,
    },
  },

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    isHtmlContent() {
      return !!this.html;
    },

    contentContainerClass() {
      if (this.theme === 'theme2') {
        switch (this.tag) {
          case 'h1':
            return 'tw-text-weight-light tw-text-5xl tw-text-[var(--nc-ternary-color)]';
          case 'h2':
            return 'tw-text-weight-light tw-text-3xl tw-text-[var(--dark-brown)]';
          case 'h3':
            return 'heading-xsmall';
          case 'h4':
            return 'tw-text-weight-light tw-text-lg tw-text-[var(--nc-ternary-color)]';
          case 'h5':
            return 'tw-text-weight-light tw-text-base tw-text-[var(--nc-ternary-color)]';
          case 'h6':
            return 'tw-text-weight-light tw-text-sm tw-text-[var(--nc-ternary-color)]';
          default:
            return '';
        }
      } else if (this.theme === 'theme3') {
        if (this.tag === 'h1') {
          return 'tw-text-5xl';
        }
        if (this.tag === 'h2') {
          return 'tw-font-semibold tw-text-3xl lg:tw-text-4xl';
        } else if (this.tag === 'h3') {
          return 'tw-font-semibold tw-text-2xl';
        } else if (this.tag === 'h4') {
          return 'tw-font-semibold tw-text-lg';
        } else if (this.tag === 'h5') {
          return 'tw-font-semibold tw-text-base';
        } else if (this.tag === 'h6') {
          return 'tw-font-semibold tw-text-sm';
        }
      }
      return '';
    },
  },
});
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--default-title-font);
}
</style>
